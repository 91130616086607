<template>
    <div class="axil-signin-area">
        <!-- Start Header -->
        <div class="signin-header">
            <div class="row align-items-center">
                <div class="col-xl-4 col-sm-6">
                    <router-link to="/" class="site-logo"
                        ><img
                            src="logo.png"
                            alt="logo"
                            style="max-height: 100px"
                    /></router-link>
                </div>
                <div class="col-md-2 d-lg-block d-none">
                    <router-link to="/login" class="back-btn"
                        ><i class="far fa-angle-left"></i
                    ></router-link>
                </div>
                <div class="col-xl-6 col-lg-4 col-sm-6">
                    <div class="singin-header-btn">
                        <p>{{ $t("Van már fiókod?") }}</p>
                        <router-link
                            to="/login"
                            class="sign-up-btn axil-btn btn-bg-secondary"
                            >{{ $t("Belépés") }}</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- End Header -->

        <div class="row">
            <div class="col-xl-4 col-lg-6">
                <div
                    class="axil-signin-banner bg_image bg_image--10"
                    style="background: url('/ragavan.png'); background-repeat: no-repeat; background-size: cover;"
                >
                    <h3 class="title" style="color: white">
                        {{
                            $t(
                                "Magic Singles - Magyarország legnagyobb Online Mtg Single Webshopja"
                            )
                        }}
                    </h3>
                </div>
            </div>
            <div class="col-lg-6 offset-xl-2">
                <div class="axil-signin-form-wrap">
                    <div class="axil-signin-form">
                        <h3 class="title">
                            {{ $t("Elfelejtetted jelszavadat?") }}
                        </h3>
                        <p class="b2 mb--55">
                            {{
                                $t(
                                    "Add meg az E-mail címedet és elküldjük a jelszó helyreállítási linket."
                                )
                            }}
                        </p>
                        <form class="singin-form" v-on:submit.prevent="doReset">
                            <div class="form-group">
                                <label>{{ $t("Email") }}</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    v-model="model.email"
                                    placeholder="annie@example.com"
                                />
                            </div>
                            <div class="form-group">
                                <button
                                    type="submit"
                                    class="axil-btn btn-bg-primary submit-btn"
                                >
                                    {{
                                        $t("Jelszóhelyreállítási link küldése")
                                    }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "ForgetPass",
    data() {
        return {
            model: {
                email: null
            }
        };
    },
    methods: {
        doReset() {
            const self = this;
            ApiService.post(`/forgot-password`, this.model).then(() => {
                this.model = {
                    email: null
                };

                self.toastMessage(
                    self.$translate.t("Success!"),
                    self.$translate.t("We've sent the instructions in email.")
                );
            });
        }
    }
};
</script>

<style scoped></style>
